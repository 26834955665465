.herocontainer {
    /* padding:0% 14.5%; */
    height: auto;
    width: 100%;
    border-radius: 8px ;
    margin-bottom:14px;
  }
  @media screen and (max-width: 620px) {
    .herocontainer{
      width: 100%;
      padding:0px;
      padding: 1px;
      height: auto;
      margin-top:12px;
/*       margin: 1vh 0vw;
 */      
    }
  }

  