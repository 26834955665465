.client-user{
    flex: 4;
    padding:20px;
}
.userTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px;
}

.userContainer{
    display: flex;
    margin-top: 20px;
    
}
.userShow{
    padding: 20px;
    width: 95%;
    margin: 3px 5px;
   /*  box-shadow:0px 0px 15px -10px rgb(0,0,0.75);
    -webkit-box-shadow:0px 0px 15px -10px rgb(0,0,0.75); */
    
}
.card{
    border:'0px solid';
    background-image:linear-gradient(to top right, var(--app-primary), white);border-radius:4px
}

.reward{
    border:'0px solid';
    background-image:linear-gradient(to top right, var(--app-secondary), var(--app-secondary));border-radius:4px;
   /*  border-top-left-radius: 30px;
    border-top-right-radius: 30px; */
    margin-bottom: -6px;

}

.cardOutlined{
    border: 0px solid var(--app-primary);
    background-image:linear-gradient(to top right, white, white)
    ;border-radius:4px;
}

.userShowTop{
    display: flex;
    align-items: center;
    border-top-left-radius: 10Px;
    border-top-right-radius: 10px;
}
.userShowImg{
    width: 45px;
    height: 45px;
    border:2px solid var(--app-primary);
    border-radius: 50%;
    object-fit: cover;
}

.userShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
   
}
.userShowUsername{
    font-weight: 600;
}
.userShowUserTitle{
    font-size: small;
    font-weight: 300;
}
.userShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: darkgray;
}
.userShowBottom{
    margin-top: 20px;
}
.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;

}
.emailverify{
    width: '100%';

}

.userShowIcon{
    font-size: 24px !important;
}
.userShowInfoTitle{
    margin-left: 10px;
}
.client-userUpdateForm{
    display: block;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom:48px;
}
.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 10px;
}
.userUpdateTitle{
  font-size: 24px;
  font-weight: 600;
}
.userUpdateItem{
    display: flex;
    margin-top: 10px;
    width:100%
}
.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
    width: 40%;
}
.userUpdateItem>input{
    width: 60%;
}
.userUpdateInput{
    border: none;
    width: 80%;
    height: 30px;
    border-bottom: 0px solid gray;
}
.userUpdateUploadWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.userUpdateUpload{
    display: flex;
    align-items: center;
}
.userUpdateIcon{
    cursor: pointer;
}

.offline{
    background-color: red;
    border: 1px solid red;
    border: 90px;
}
.userUpDateButton{
    border-radius: 5px;
    border: none;
    padding:5px;
    height:40px;
    cursor: pointer;

    background-color: cornflowerblue;
    color: white;
    font-weight: 600;
    font-size: 14px;
}
.log{
  color: var(--app-primary);
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width:620px){
    .userUpdateForm{
        margin-top: 1px;
    }
    .client-userShow{
        padding: 2px 3px;
        width:100%;
    }
    .useraddress{
        font-size: 0.8rem;
    }
    .userUpdateInput{
      
        width: 100% !important;
      
    }
    .userUpdateUploadWrapper{
        padding: 10px 10px;
        width: 100%;
        justify-content: space-around;
       margin-bottom: 10px;
    }
   
    .userUpDateButton{
        margin-top: 10px ;
    }
    .reward{
       
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    
    }
    
}